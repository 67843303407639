.calendly {
    width: 100%;
    padding-block: 3rem;
    margin: 0px 0px 100px;
  }
  .calendlyHeading{
    font-size: 32px;
    text-align: center;
    margin: auto;
    margin-bottom: 0px;
    display: block;
    font-weight: bold;
  }
  .calendly-inline-widget {
    min-height: 690px !important;
  }
  
  @media screen and (max-width: 350px) {
    .calendly {
      width: 290px;
    }
    .calendly .calendly-inline-widget {
      min-width: 100% !important;
    }
  }
  


  .successToast{
    width: 500px !important;
    height: 100% !important;
    top: 42% !important;
    margin: 0 auto !important;
    z-index: 99;
  }
  .modalMain {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 20px 20px 30px 20px;
  }
  .modal-body{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  } 
  .modal-close {
        color: #aaa;
        line-height: 50px;
        font-size: 80%;
        position: absolute;
        right: 0;
        top: 0;
        width: 70px;
        text-decoration: none;
        &:hover {
          color: black;
        }
      }
      .successToast .shadow {
        border-radius: 30px !important;
      }
      @media only screen and (max-width: 768px) {
        .successToast{
          width: 460px !important;
        }
      }
      @media only screen and (max-width: 650px) {
        .successToast{
          width: 430px !important;
        }
      }
      @media only screen and (max-width: 476px) {
        .successToast{
          width: 380px !important;
        }
      }
      @media only screen and (max-width: 376px) {
        .successToast{
          width: 320px !important;
        }
      }